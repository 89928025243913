import { Link, useNavigate, useParams } from "react-router-dom";
import { Box } from "../components/Box";
import { useEffect, useState } from "react";
import { VacancyRespondedResponse } from "../api/models/response/VacancyResponse";
import VacancyService from "../api/services/VacancyService";

export function VacancyResponded() {
    useEffect(() => {
        document.title = "Отклики на вакансию";
        showAllInfo();
    }, []);

    const { id } = useParams<{ id: string }>();
    const vacancyId = id ?? "0"; // или любое другое значение по умолчанию

    const [pageOfTable, setPageOfTable] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(0);

    const [responded, setResponded] = useState<VacancyRespondedResponse[]>([]);
    
    const navigate = useNavigate(); // Получите доступ к navigate

    const [stateShowAllInfo, setStateShowAllInfo] = useState<boolean>(false);

    const showAllInfo = async () => {
        try {
            const response = await VacancyService.showAllInfo();
            setStateShowAllInfo(response.data);
        } catch (error) {
            // console.log("Ошибка получения вакансий:", error);
        }
    }

    useEffect(() => {
        setResponded([]);
        if( vacancyId === undefined || vacancyId === null) {
            return;
        }
        const fetchResponded = async(pageOfTable: number) => {
            try{
                const response = await VacancyService.getVacancyResponds(parseInt(vacancyId), pageOfTable, 10);
                if(response.data.length === 0) {
                    setLastPage(pageOfTable);
                    return;
                }
                setResponded(response.data);
            }
            catch(error){
                // console.log("Ошибка получения вакансий:", error);
            }
        }
        fetchResponded(pageOfTable);
    }, [pageOfTable])

    const [isModalVisible, setModalVisible] = useState(false);


    return (
        <div>
            {isModalVisible &&
            <>
                <div 
                    className="modal-backdrop" 
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Полупрозрачный черный фон
                        zIndex: 1040 // Убедитесь, что фон под модальным окном
                    }} 
                />
                <div className="modal" tabIndex={-1} data-toggle="modal" data-target="#exampleModal" style={{display: 'block'}}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="exampleModalLabel">Информация о сведениях</h5>
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={() => setModalVisible(false)}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className='mb-0'>{"Доброго времени суток, мы заботимся о том, чтоб контакты наших пользователей были доступны только проверенным физ лицам или компаниям. Для этого подтвердите данные созданной указанной компании связавшись с нами."} </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setModalVisible(false)}>Закрыть</button>
                            <Link className="text-decoration-none" to="/aboutUs">
                                <button type="button" className="btn-violet medium" style={{borderRadius:"0.375rem"}}>Перейти к нашим контактам</button>
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </> 
            }

            <Box styleClass="bg-white">
                <div className="mb-2 align-items-center">
                    <div className="row">   
                        <div className="col align-items-center">
                            <Link to="/companyVacancys" className=" text-decoration-none" style={{color:"blue", fontSize:"12px"}}> {"<"} Вернуться к вакансиям</Link>
                            <p className="text-color-gray fw-bold p-0 m-0" style={{fontSize:"16px"}}>
                                Отклики на вакансию
                            </p>
                        </div>
                        { !stateShowAllInfo &&
                        <>
                            <div className="col d-flex justify-content-end align-items-center d-none d-md-flex">
                                <button className="btn-violet medium p-3 mb-2 " style={{borderRadius:"0.375rem"}} onClick={() => setModalVisible(true)}>
                                    Показать сведения
                                </button>
                            </div>
                            <div className="d-flex d-md-none d-flex justify-content-start align-items-center">
                                <button className="btn-violet medium p-3 mb-2 " style={{borderRadius:"0.375rem"}} onClick={() => setModalVisible(true)}>
                                    Показать сведения
                                </button>
                            </div>
                        </>
                        }
                    </div>
                </div>
                <p className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                    <hr className="m-0 w-100"></hr>
                </p>
                <div className="w-100" >
                <table className="table mt-4 w-100 ">
                    <thead style={{backgroundColor:"#4E4FF6"}}>
                        <tr>
                            <th className="" style={{backgroundColor:"#4E4FF6", color:"white"}} scope="col">Имя</th>
                            <th className="" style={{backgroundColor:"#4E4FF6", color:"white"}} scope="col">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responded.length === 0 && (
                            <tr>
                                <td className="text-center" colSpan={5}>Ничего не найдено</td>
                            </tr>
                        )}
                        {responded.map((responder) => (
                            <tr className="align-middle">
                                <th className="">
                                    {
                                        stateShowAllInfo ? 
                                        <Link to={`/profile/${responder.id}`} className="text-decoration-none text-black">
                                            <div className="d-flex align-items-center flex-wrap">
                                                {
                                                    responder.avatar ? 
                                                    <img className="rounded-circle me-3" src={responder.avatar} style={{ width: "40px", height: "40px", objectFit: "cover" }} alt="User Icon" />
                                                    :
                                                    <img className="rounded-circle me-3" src="/images/icons/user/user-default.jpg" style={{ width: "40px", height: "40px", objectFit: "cover" }} alt="User Icon" />
                                                }
                                                <span className="flex-wrap">{responder.first_name + " " + responder.second_name}</span>
                                            </div>
                                        </Link>
                                        :
                                        <div className="d-flex align-items-center flex-wrap">
                                                {
                                                    responder.avatar ? 
                                                    <img className="rounded-circle me-3" src={responder.avatar} style={{ width: "40px", height: "40px", objectFit: "cover" }} alt="User Icon" />
                                                    :
                                                    <img className="rounded-circle me-3" src="/images/icons/user/user-default.jpg" style={{ width: "40px", height: "40px", objectFit: "cover" }} alt="User Icon" />
                                                }
                                                <span className="flex-wrap">{responder.first_name + " " + responder.second_name}</span>
                                            </div>
                                    }
                                </th>
                                <th className="">{responder.email}</th>
                                {/* <th>
                                    <div className="btn-group">
                                        <button className="btn-bright medium gap-1 fw-bold" type="button" id="dropdownMenu2" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                                            ...
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                                            <li>
                                                <button className="dropdown-item text-black" type="button" onClick={() => handleViewVacancy(vacancy.id)}>Просмотреть</button>
                                            </li>
                                            <li><button className="dropdown-item text-black" type="button" onClick={() => handleEditVacancy(vacancy.id)}>Редактировать</button></li>
                                            <li><button className="dropdown-item text-black" type="button" onClick={() => handleEditVacancy(vacancy.id)}>Отклики</button></li>
                                            <li><button className="dropdown-item text-black" type="button" onClick={() => {deleteVacancy(vacancy.id ?? 0)}} >Удалить</button></li>
                                        </ul>
                                    </div>
                                </th> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="row">
                    <div className="d-flex justify-content-end">
                        <div className="d-flex me-2 align-items-center">
                            {
                                pageOfTable==1 ? 
                                <button className="btn-bright medium me-2 opacity-50" disabled style={{fontSize: "30px"}} onClick={()=> {setPageOfTable(pageOfTable - 1)}} >{"<"}</button>
                                : 
                                <button className="btn-bright medium me-2" style={{fontSize: "30px"}} onClick={()=> {setPageOfTable(pageOfTable - 1)}} >{"<"}</button>
                            }
                            <button className={pageOfTable==1 ? "btn-violet medium me-2" : "btn-bright medium me-2"} style={{fontSize: "20px"}} onClick={()=> {setPageOfTable(1)}}>{pageOfTable == 1 ? 1 : pageOfTable - 1}</button>
                            <button className={pageOfTable==pageOfTable && pageOfTable !==1  ? "btn-violet medium me-2" : "btn-bright medium me-2"} style={{fontSize: "20px"}} onClick={()=> {setPageOfTable(2)}}>{pageOfTable == 1 ? 2 : pageOfTable}</button>
                            {
                                pageOfTable==lastPage ? 
                                <button className="btn-bright medium opacity-50" disabled style={{fontSize: "30px"}} onClick={()=> {setPageOfTable(pageOfTable + 1)}} >{">"}</button>
                                : 
                                <button className="btn-bright medium" style={{fontSize: "30px"}} onClick={()=> {setPageOfTable(pageOfTable + 1)}} >{">"}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </Box>
        </div>
    )
}