import { useEffect, useState } from "react";
import VacancyService from "../../api/services/VacancyService";
import { AvailableVacancy } from "../../api/models/response/VacancyResponse";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "../../components/Box";

export function CompanyVacancys() {
    useEffect(() => {
        document.title = "Вакансии компании";
    }, []);
    
    const [pageOfTable, setPageOfTable] = useState<number>(1);
    const [vacancys, setVacancys] = useState<AvailableVacancy[]>([]);
    const [lastPage, setLastPage] = useState<number>(0);
    
    const navigate = useNavigate();

    const handleViewVacancy = (id: number | null) => {
        if (id !== null) {
            navigate(`/vacancy/${id}`);
        }
    };

    const handleEditVacancy = (id: number | null) => {
        if (id !== null) {
            navigate(`/editVacancy/${id}`);
        }
    };

    const handleVacancyResponses = (id: number | null) => {
        if (id !== null) {
            navigate(`/vacancyResponded/${id}`);
        }
    };

    useEffect(() => {
        setVacancys([]);
        const fetchVacancys = async (pageOfTable: number) => {
            try {
                const response = await VacancyService.getAvailableVacancies(pageOfTable, 10);
                if (response.data.length === 0) {
                    setLastPage(pageOfTable);
                    return;
                }
                setVacancys(response.data);
            } catch (error) {
                // console.log("Ошибка получения вакансий:", error);
            }
        };
        fetchVacancys(pageOfTable);
    }, [pageOfTable]);

    const deleteVacancy = async (id: number) => {
        try {
            const response = await VacancyService.deleteVacancy(id);
            if (response.status === 200) {
                setVacancys(vacancys.filter(vacancy => vacancy.id !== id));
            }
            navigate(`/companyVacancys`);
        } catch (error) {
            // console.log("Ошибка удаления вакансии:", error);
        }
    };

    return (
        <div className="container">
            <Box styleClass="bg-white">
                <div className="row p-0 m-0">
                    <div className="col d-flex align-items-center">
                        <h3 className="fw-bold mb-0">Список вакансий</h3>
                    </div>
                    <div className="col-md d-flex justify-content-end m-0 pe-0 d-none d-md-flex">
                        <Link to="/addVacancy" className="text-decoration-none">
                            <button className="btn-violet medium p-3" style={{borderRadius:"0.375rem"}}>
                                Добавить вакансию
                            </button>
                        </Link>
                    </div>
                    <div className="d-flex d-md-none justify-content-start mt-2 m-0">
                        <Link to="/addVacancy" className="text-decoration-none">
                            <button className="btn-violet medium p-3 w-100" style={{borderRadius:"0.375rem"}}>
                                Добавить вакансию
                            </button>
                        </Link>
                    </div>
                </div>
                <hr className="w-100" />
                <div className="w-100">
                    <div className="table-responsive">
                        <table className="table mt-4 w-100">
                            <thead style={{ backgroundColor: "#4E4FF6" }}>
                                <tr>
                                    <th style={{ backgroundColor: "#4E4FF6", color: "white" }}>Название</th>
                                    <th style={{ backgroundColor: "#4E4FF6", color: "white" }}>Дата создания</th>
                                    <th style={{ backgroundColor: "#4E4FF6", color: "white" }}>Дата завершения</th>
                                    <th style={{ backgroundColor: "#4E4FF6", color: "white" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {vacancys.length === 0 && (
                                    <tr>
                                        <td className="text-center" colSpan={4}>Ничего не найдено</td>
                                    </tr>
                                )}
                                {vacancys.map((vacancy) => (
                                    <tr className="align-middle" key={vacancy.id}>
                                        <th>
                                            <Link to={`/vacancy/${vacancy.id}`} className="text-decoration-none text-black">{vacancy.name}</Link>
                                        </th>
                                        <td>{vacancy.created_at ? new Date(vacancy.created_at).toLocaleDateString() : ""}</td>
                                        <td>{vacancy.completion_date ? new Date(vacancy.completion_date).toLocaleDateString() : ""}</td>
                                        <td>
                                            <div className="btn-group" style={{ position: 'relative' }}>
                                                <button className="btn-bright medium gap-1 fw-bold" type="button" id="dropdownMenu2" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                                                    ...
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end" style={{ position: 'absolute', zIndex: 1000}}>
                                                    <li>
                                                        <button className="dropdown-item text-black" type="button" onClick={() => handleViewVacancy(vacancy.id)}>Просмотреть</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item text-black" type="button" onClick={() => handleEditVacancy(vacancy.id)}>Редактировать</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item text-black" type="button" onClick={() => handleVacancyResponses(vacancy.id)}>Отклики</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item text-black" type="button" onClick={() => deleteVacancy(vacancy.id ?? 0)}>Удалить</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-end">
                            <div className="d-flex me-2 align-items-center">
                                <button className={`btn-bright medium me-2 ${pageOfTable === 1 ? "opacity-50" : ""}`} disabled={pageOfTable === 1} style={{ fontSize: "30px" }} onClick={() => setPageOfTable(pageOfTable - 1)}>{"<"}</button>
                                <button className={`btn-violet medium me-2 ${pageOfTable === 1 ? "" : "btn-bright"}`} style={{ fontSize: "20px" }} onClick={() => setPageOfTable(1)}>{pageOfTable === 1 ? 1 : pageOfTable - 1}</button>
                                <button className={`btn-violet medium me-2 ${pageOfTable === 2 ? "" : "btn-bright"}`} style={{ fontSize: "20px" }} onClick={() => setPageOfTable(2)}>{pageOfTable === 1 ? 2 : pageOfTable}</button>
                                <button className={`btn-bright medium ${pageOfTable === lastPage ? "opacity-50" : ""}`} disabled={pageOfTable === lastPage} style={{ fontSize: "30px" }} onClick={() => setPageOfTable(pageOfTable + 1)}>{">"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
}
