    import { useContext, useEffect, useRef, useState } from "react";
    import { Link, useParams } from "react-router-dom";
    import { Context } from "../App";
    import { Box, BoxMap, BoxMini } from "../components/Box";
    import { Education } from "../api/models/Education";
    import { Tag } from "../components/Tag";
    import { User, UserInformation } from "../api/models/User";
    import { ResumeInformation } from "../api/models/Resume";
    import AuthService from "../api/services/AuthService";
    import { jsPDF } from "jspdf";
    import { toPng } from 'html-to-image';
    import ReactToPrint from "react-to-print";
import LoadingScreen from "../components/LoadingScreen";

    export function Profile() {
        const store = useContext(Context);
        const { id } = useParams<{ id: string }>();
        const profileId = id ?? "0"; // или любое другое значение по умолчанию
        const [user, setUser] = useState<UserInformation | undefined>(undefined);
        const [resume, setResume] = useState<ResumeInformation | null>(null);
        const [educations, setEducations] = useState<Education[] | null>(null);
        const [pdfHideComponents, setPdfHideComponents] = useState<boolean>(false);
        const [canDownloadPdf, setCanDownloadPdf] = useState<boolean>(false);
        
        useEffect(() => {
            if (parseInt(profileId) === store?.userBriefInformation?.user_id) {
                setUser(store?.user as UserInformation | undefined);
                setResume(store?.resume as ResumeInformation | null);
                setEducations(store?.education as Education[]  | null);
                document.title = (store?.user?.name && store) ? `${store.user.surname} ${store.user.name}` : "";
            } 
        }, [store]);

        useEffect(() => {
            if(parseInt(profileId) !== store?.userBriefInformation?.user_id && store?.isAuth){
                fetchUser(); // Ensure this is a function call
                fetchResume(); // Ensure this is a function call
                document.title = (user) ? `${user.name} ${user.surname}` : "";
            }
        }, [profileId])

        
        const canDownloadPdfFunction = async() => {
            try{
                const response = await AuthService.CanDownloadResume();
                setCanDownloadPdf(response.data);
            }
            catch(error){
                // console.log(error);
            }
        }

        useEffect(() => {
            canDownloadPdfFunction();
        }, [])


        const fetchUser = async() => {
            try{
                const response = await AuthService.getProfileBasicInfoWithId(parseInt(profileId));
                setUser(response.data);
            }
            catch(error){
                // console.log(error);
            }
        }

        const fetchResume = async() => {
            try{
                const response = await AuthService.getResumeWithId(parseInt(profileId));
                setResume(response.data);
                setEducations(response.data.educations);
            }
            catch(error){
                // console.log(error);
            }
        }
        
        // const resumeForPDF = () => {
        //     return (
        //         <>
        //             {user && store?.isAuth && 
        //                 <div className="container pt-4 ps-2 pe-2 pb-2" ref={pdfRef} id="pdfContainer" style={{ backgroundColor: "#f3f3f4" }}>
        //                 {store &&  <div className="flex-column w-100">
        //                         <div>
        //                             <BoxMini styleClass={"bg-white mb-4 position-relative"} >
        //                                 <div className="row">
        //                                     <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-3 mb-md-0">
        //                                         <div className="w-50 d-flex justify-content-center align-items-center">
        //                                             {
        //                                                 user?.avatar_url ?
        //                                                 <img src={`https://cors-anywhere.herokuapp.com/${user?.avatar_url}`}  className="rounded-circle img-fluid" style={{ height: "30vw", width: "30vw", maxHeight: "186px", maxWidth: "186px", objectFit: "cover" }} alt="Profile" />
        //                                                 :
        //                                                 <img src="/images/icons/user/user-default.jpg" className="rounded-circle img-fluid" style={{ height: "30vw", width: "30vw", maxHeight: "186px", maxWidth: "186px", objectFit: "cover" }} alt="Profile" />
        //                                             }
        //                                         </div>
        //                                         <div className="w-50 d-flex align-items-center ms-2">
        //                                             <div>
        //                                                 <h3 className="fw-bold fs-4">{user?.surname} {user?.name}</h3>
        //                                                 <div className="d-flex align-items-center">
        //                                                     <img className="me-2" src="/images/icons/user/user-years.png" style={{ width: "17px", height: "17px" }} alt="Age" />
        //                                                     <span className="fs-6">{user?.date_of_birth ? Math.floor((new Date().getTime() - new Date(user.date_of_birth).getTime()) / (1000 * 60 * 60 * 24 * 365)).toString() + " года" : "Дата рождения не указана"}</span>
        //                                                 </div>
        //                                                 <div className="d-flex align-items-center">
        //                                                     <img className="me-2" src="/images/icons/user/user-location.png" style={{ width: "17px", height: "17px" }} alt="Location" />
        //                                                     {user?.city ? 
        //                                                         (<span className="fs-6">{user?.city?.name}, {user?.city?.country}</span>)
        //                                                         :
        //                                                         (<span className="fs-6">Город не указан</span>)
        //                                                     }
        //                                                 </div>
        //                                                 {store?.education && (
        //                                                     <div className="d-flex align-items-center">
        //                                                         <img className="me-2" src="/images/icons/user/user-education.png" style={{ width: "17px", height: "17px" }} alt="Education" />
        //                                                         <span className="fs-6">{lastEducation ? `${lastEducation.university?.name} (${lastEducation.year_of_graduation})` : 'Нет данных об образовании'}</span>
        //                                                     </div>
        //                                                 )}
        //                                                 {user?.about_me &&
        //                                                     <div className="d-flex align-items-center">
        //                                                         <span className="fs-6">{user?.about_me}</span>
        //                                                     </div>
        //                                                 }
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
        //                                         {user?.about_me_tags && user?.about_me_tags.length > 0 &&
        //                                             <div className="w-100">
        //                                                 <span className="text-black fs-6">Коротко о себе:</span>
        //                                                 <div className="d-flex justify-content-start align-items-center flex-wrap">
        //                                                     <div className="row row-cols-auto gap-3 ms-0">
        //                                                         {user?.about_me_tags?.map((tag, index) => (
        //                                                             <Tag key={index} tag={tag} colorClass={colors[index % colors.length]} />
        //                                                         ))}
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         }
        //                                     </div>
        //                                 </div>

        //                                 {/* <div className="w-100 mt-2">
        //                                     <span className="fs-6">{user?.about_me}</span>
        //                                 </div> */}
        //                             </BoxMini>

        //                             <BoxMini styleClass="bg-white mb-4 position-relative">
        //                                 <div className="row">
        //                                     <div className="col-auto d-flex align-items-center flex-wrap">
        //                                         <h3 className="fw-bold fs-4 me-2">Должность:</h3>
        //                                         <h3 className="fs-4 w-100">{(resume?.name_post && resume?.name_post !== "") ? resume?.name_post : "Должность не выбрана"}</h3>
        //                                     </div>
        //                                 </div>
        //                                 <h3 className="fw-bold mt-2 fs-4">Навыки</h3>
        //                                 <div className="row row-cols-auto gap-3 ms-0">
        //                                     {resume?.skills?.length !== 0 && resume?.skills?.map((skill, index) => (
        //                                         <Tag tag={skill} styleClass={"bg-gray"} key={index} />
        //                                     ))}
        //                                     {resume?.skills?.length === 0 && <h3 className="fs-4 w-100 p-0">Навыки не выбраны</h3> }
        //                                 </div>
        //                                 <h3 className="fw-bold mt-3 fs-4">Желаемый формат работы</h3>
        //                                 <div className="d-flex row row-cols-auto gap-3 ms-0">
        //                                     {
        //                                         (resume?.work_format?.name !== null && resume?.work_format?.name !== undefined)  ? 
        //                                         <Tag tag={resume?.work_format?.name} />
        //                                         : <h3 className="fs-4 w-100 p-0">Формат работы не выбран</h3> 
        //                                     }
        //                                 </div>
        //                                 <h3 className="fw-bold mt-3 fs-4">Языки</h3>
        //                                 <div>
        //                                     {resume?.languages?.length !== 0 && resume?.languages?.map((language, index) => (
        //                                         <div className="mt-1" key={index}>
        //                                             <span className="fs-6">{language.name + ":"}</span>
        //                                             <span className="text-black text-decoration-underline  ms-2 fs-6">{language.level}</span>
        //                                         </div>
        //                                     ))} 
        //                                     {resume?.languages?.length === 0 && <h3 className="fs-4 w-100 p-0">Языки не выбраны</h3> }
        //                                 </div>  
        //                             </BoxMini>

        //                             <BoxMini styleClass="bg-white position-relative">
        //                                 <div className="row">
        //                                     <h3 className="fw-bold mt-2 fs-4">Опыт работы</h3>
        //                                 </div>
        //                                 {resume?.experiences && resume?.experiences.map((experience) => (
        //                                     <BoxMap styleClass="" key={experience.id}>
        //                                         <div className="d-flex justify-content-start">
        //                                             <h5 className="fw-bold fs-5">{experience.title_position}</h5>
        //                                         </div>
        //                                         <h5 className="fw-bold mb-2" style={{ color: "blue" }}>{experience.company_name}</h5>
        //                                         <span className="mb-2 d-block" style={{ color: "gray" }}>{experience.getting_started} - {experience.end_work}</span>
        //                                         <span>{experience.description}</span>
        //                                         {resume && resume.experiences &&    
        //                                             experience.id !== resume?.experiences[resume?.experiences?.length - 1].id && (
        //                                             <hr className="mb-2" />
        //                                         )}
        //                                     </BoxMap>
        //                                 ))}
        //                             </BoxMini>

        //                             <BoxMini styleClass="bg-white mb-4 position-relative">
        //                                 <div className="row">
        //                                     <div className="col">
        //                                         <h3 className="fw-bold mt-2 fs-4">Образование</h3>
        //                                     </div>
        //                                 </div>
        //                                 {educations && educations?.map((education) => (
        //                                     <BoxMap key={education.id} styleClass="mb-0">
        //                                         <div className="d-flex justify-content-start">
        //                                             <h5 className="fw-bold fs-5">{education.university?.name}</h5>
        //                                         </div>
        //                                         <h5 className="fw-bold mb-2" style={{ color: "blue" }}>{education.level_of_education}</h5>
        //                                         <span className="mb-2 d-block" style={{ color: "gray" }}>{education.year_of_admission + " - " + education.year_of_graduation}</span>
        //                                         <span>{education.specialty}</span>
        //                                         {education &&
        //                                             education.id !== educations[educations.length - 1].id && (
        //                                                 <hr className="mb-2" />
        //                                             )}
        //                                     </BoxMap>
        //                                 ))}
        //                             </BoxMini>
        //                         </div>
        //                     </div>}
        //                 </div>
        //             }
        //         </>
        //     )
        // }


        // const saveImageToSessionStorage = (key: string, imageUrl: string) => {
        //     fetch(imageUrl)
        //         .then(response => response.blob())
        //         .then(blob => {
        //             const reader = new FileReader();
        //             reader.onloadend = () => {
        //                 const base64data = reader.result as string;
        //                 sessionStorage.setItem(key, base64data);
        //             };
        //             reader.readAsDataURL(blob);
        //         })
        //         .catch(error => console.error('Error fetching image:', error));
        // };

        const pdfRef = useRef<HTMLDivElement>(null)
        const downloadPDF = async () => {
            setPdfHideComponents(true);
            if (pdfRef.current === null) {
                return;
            }
            const options = {
                cacheBust: true,
                useCORS: true,
                allowTaint: false,
                logging: true,
            };
        
            toPng(pdfRef.current)
            .then(async (dataUrl) => {
                const img = new Image();
                img.src = dataUrl;
                sessionStorage.setItem('pdfImage', dataUrl);
        
                img.onload = async () => {
                    const pdf = new jsPDF('p', 'mm', 'a4', true);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = pdf.internal.pageSize.getHeight();
                    const imgWidth = img.width * 0.264583;
                    const imgHeight = img.height * 0.264583;
        
                    const widthRatio = pdfWidth / imgWidth;
                    const heightRatio = pdfHeight / imgHeight;
                    const scale = Math.min(widthRatio, heightRatio);
        
                    const newWidth = imgWidth * scale;
                    const newHeight = imgHeight * scale;
                    const x = (pdfWidth - newWidth) / 2;
                    const y = 20;
                    // Получение изображения из sessionStorage
                    const storedImage = sessionStorage.getItem('pdfImage');
                    if (storedImage) {
                        const blob = await fetch(storedImage).then(res => res.blob());
                        const url = URL.createObjectURL(blob);
                        pdf.addImage(url, 'PNG', x, y, newWidth, newHeight - 5);
                    } else {
                        console.error('No image found in sessionStorage');
                    }
        
                    // Добавление заголовка в PDF
                    const topImageUrl = '/images/pdf_header.png';
                    const headerResponse = await fetch(topImageUrl);
                    if (headerResponse.ok) {
                        const headerBlob = await headerResponse.blob();
                        const headerUrl = URL.createObjectURL(headerBlob);
                        pdf.addImage(headerUrl, 'PNG', 0, 0, pdfWidth, 20);
                    } else {
                        console.error('Error loading header image:', headerResponse.statusText);
                    }
        
                    pdf.save(`Резюме ${user?.surname} ${user?.name}.pdf`);
                    setPdfHideComponents(false);
                };
            })
            .catch((err) => {
                console.error('Error generating image:', err);
            });
        };

        // const downloadPDF = () => {
        //     setPdfHideComponents(true);
        //     if (pdfRef.current === null) {
        //         return;
        //     }
        
        //     const element = pdfRef.current;
        
        //     const options = {
        //         margin: 0,
        //         filename: `Резюме ${user?.surname} ${user?.name}.pdf`,
        //         image: { type: 'jpeg', quality: 0.98 },
        //         html2canvas: { scale: 2 },
        //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        //     };
        
        //     html2pdf(element, options)
        //         .from(element)
        //         .set(options)
        //         .save()
        // };
        
        const colors = [
            "#B2E9AA",
            "#E9AAAA",
            "#E9DBAA",
            "#AAE9E5",
            "#B2AAE9",
            "#B2E9AA",
            "#B2AAE9",
        ];
        const [isModalVisible, setModalVisible] = useState(false);
        
        const lastEducation = educations && educations?.reduce<Education | null>((latest, current) => {
            if (!latest || (latest.year_of_graduation !== null && current.year_of_graduation !== null && current.year_of_graduation > latest.year_of_graduation)) {
                return current;
            }
            return latest;
        }, null);

        return (
            <>
                {isModalVisible &&
                    <>
                        <div 
                            className="modal-backdrop" 
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Полупрозрачный черный фон
                                zIndex: 1040 // Убедитесь, что фон под модальным окном
                            }} 
                        />
                        <div className="modal" tabIndex={-1} data-toggle="modal" data-target="#exampleModal" style={{display: 'block'}}>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                <div className="modal-header" >
                                    <h5 className="modal-title" id="exampleModalLabel">Информация о сведениях</h5>
                                    <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={() => setModalVisible(false)}>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className='mb-0'>{"Доброго времени суток, функция скачивания резюме является платным фунционалом. Для этого свяжитесь с нами."} </p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setModalVisible(false)}>Закрыть</button>
                                    <Link className="text-decoration-none" to="/aboutUs">
                                        <button type="button" className="btn-violet medium" style={{borderRadius:"0.375rem"}}>Перейти к нашим контактам</button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </> 
                }
                {pdfHideComponents && <LoadingScreen />}
                {user && store?.isAuth && 
                    <div className= { !pdfHideComponents ? "container" : "pt-2 pb-2 ps-2 pe-2" } ref={pdfRef} id="pdfContainer" style={{ backgroundColor: "#f3f3f4" }}>
                    {pdfHideComponents &&
                        <div className="w-100 bg-white mb-2" style={{borderRadius: "0.375rem"}}>
                            <img src="/images/pdf_header.png" className="w-100"></img>
                        </div>
                    }
                    {store  &&  <div className="flex-column w-100">
                        {!pdfHideComponents && store.userBriefInformation?.user_id === parseInt(profileId) &&
                            <div className="d-flex justify-content-end">
                            {
                                canDownloadPdf ?
                                    <ReactToPrint
                                    trigger={() => (
                                        <button className="btn-violet medium mb-3 w-auto" style={{ borderRadius: "0.375rem" }} >
                                            Генерация резюме
                                        </button>
                                    )}
                                    content={() => pdfRef.current}
                                    onBeforeGetContent={() => {
                                        setPdfHideComponents(true);
                                        return new Promise((resolve) => {
                                            // Используйте setTimeout для задержки, чтобы дать время для обновления состояния
                                            resolve( pdfRef.current );
                                        });
                                    }}
                                    onAfterPrint={() => setPdfHideComponents(false)} />
                                    :
                                    <button className="btn-violet medium mb-3 w-auto" onClick={() => setModalVisible(true)} style={{
                                        borderRadius: "0.375rem",
                                        overflow: "hidden",   // Скрывает переполнение
                                        textOverflow: "ellipsis" // Добавляет многоточие, если текст слишком длинный
                                    }}>
                                        Генерация резюме
                                    </button>

                            }
                            {/* <button className="btn-violet medium mb-3 w-auto" onClick={() => downloadPDF()} style={{
                                borderRadius: "0.375rem",
                                overflow: "hidden",   // Скрывает переполнение
                                textOverflow: "ellipsis" // Добавляет многоточие, если текст слишком длинный
                            }}>
                                Скачать резюме в формате PDF
                            </button> */}
                        
                        </div>
                        }
                        {/* {!pdfHideComponents && 
                                <div className="d-flex justify-content-end">
                                    <PDFDownloadLink 
                                        document={<MyDocument user={user} resume={resume} educations={educations} />} 
                                        fileName="resume.pdf"
                                    >
                                        {({ loading }) => (loading ? 'Загрузка...' : 'Скачать резюме в формате PDF')}
                                    </PDFDownloadLink>
                                </div>
                            } */}
                            <div>
                                <BoxMini styleClass={"bg-white mb-4 position-relative"}>
                                    {!pdfHideComponents && parseInt(profileId) === store?.userBriefInformation?.user_id && <>
                                    <div className="d-flex justify-content-end d-sm-none">
                                        <div className="align-items-center">
                                            <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                            <Link className="text-decoration-none fs-7" to="/editSelfInformation" style={{ color: "blue" }}>Редактровать</Link>
                                        </div>
                                    </div>
                                    <div className="d-none d-sm-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-3 ">
                                        <div className="align-items-center">
                                            <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                            <Link className="text-decoration-none fs-7" to="/editSelfInformation" style={{ color: "blue" }}>Редактровать</Link>
                                        </div>
                                    </div>
                                    </>}
                                    <div className="row">
                                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-3 mb-md-0">
                                            <div className="w-50 d-flex justify-content-center align-items-center">
                                                {
                                                    user?.avatar_url ? 
                                                        !pdfHideComponents ? 
                                                            <img src={user?.avatar_url}  className="rounded-circle" style={{ height: "30vw", width: "30vw", maxHeight: "186px", maxWidth: "186px", objectFit: "cover" }} alt="Profile" />
                                                            :
                                                            
                                                                <div className="rounded-circle" style={{
                                                                    backgroundImage: `url(${user?.avatar_url})`,
                                                                    backgroundSize: "cover",      // Заполняет весь элемент
                                                                    backgroundPosition: "center",  // Центрирует изображение
                                                                    backgroundRepeat: "no-repeat", // Не повторяет изображение
                                                                    height: "30vw",
                                                                    width: "30vw",
                                                                    maxHeight: "186px",
                                                                    maxWidth: "186px"
                                                                }}>
                                                                {
                                                                    // https://cors-anywhere.herokuapp.com/
                                                                    <img src={`${user?.avatar_url}`}  className="rounded-circle img-fluid" style={{ height: "30vw", width: "30vw", maxHeight: "186px", maxWidth: "186px", objectFit: "cover" }} />
                                                                }
                                                            </div>
                                                    :
                                                    <img src="/images/icons/user/user-default.jpg" className="rounded-circle img-fluid" style={{ height: "30vw", width: "30vw", maxHeight: "186px", maxWidth: "186px", objectFit: "cover" }} alt="Profile" />
                                                }
                                            </div>
                                            <div className="w-50 d-flex align-items-center ms-2">
                                                <div>
                                                    <h3 className="fw-bold fs-4">{user?.surname} {user?.name}</h3>
                                                    <div className="d-flex align-items-center mb-1">
                                                        <img className="me-2" src="/images/icons/user/user-years.png" style={{ width: "24px", height: "24px" }} alt="Age" />
                                                        <span className="fs-6">{user?.date_of_birth ? Math.floor((new Date().getTime() - new Date(user.date_of_birth).getTime()) / (1000 * 60 * 60 * 24 * 365)).toString() + " года" : "Дата рождения не указана"}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-1">
                                                        <img className="me-2 " src="/images/icons/user/user-location.png" style={{ width: "24px", height: "24px" }} alt="Location" />
                                                        {user?.city ? 
                                                            (<span className="fs-6">{user?.city?.name}, {user?.city?.country}</span>)
                                                            :
                                                            (<span className="fs-6">Город не указан</span>)
                                                        }
                                                    </div>
                                                    {store?.education && (
                                                        <div className="d-flex align-items-start">
                                                            <img className="me-2" src="/images/icons/user/user-education.png" style={{ width: "24px", height: "24px" }} alt="Education" />
                                                            <span className="fs-6">{lastEducation ? `${lastEducation.university?.name} (${lastEducation.year_of_graduation})` : 'Нет данных об образовании'}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center" >
                                            <div>
                                            {user?.about_me_tags && user?.about_me_tags.length > 0 &&
                                                <div className="w-100">
                                                    <span className="text-black fs-6">Коротко о себе:</span>
                                                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                                                        <div className="row row-cols-auto gap-3 ms-0">
                                                            {user?.about_me_tags?.map((tag, index) => (
                                                                <Tag key={index} tag={tag} colorClass={colors[index % colors.length]} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {user?.about_me &&
                                                <div className="d-flex align-items-center mt-2">
                                                    <span className="fs-6">{user?.about_me}</span>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="w-100 mt-2">
                                        <span className="fs-6">{user?.about_me}</span>
                                    </div> */}
                                </BoxMini>

                                <BoxMini styleClass="bg-white mb-4 position-relative">
                                {!pdfHideComponents && parseInt(profileId) === store?.userBriefInformation?.user_id && <>
                                    <div className="d-flex justify-content-end d-sm-none">
                                        <div className="align-items-center">
                                            <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                            <Link className="text-decoration-none fs-7" to="/editProfessionalProfile" style={{ color: "blue" }}>Редактровать</Link>
                                        </div>
                                    </div>
                                    <div className="d-none d-sm-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-3 ">
                                        <div className="align-items-center">
                                            <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                            <Link className="text-decoration-none fs-7" to="/editProfessionalProfile" style={{ color: "blue" }}>Редактровать</Link>
                                        </div>
                                    </div>
                                    </>}
                                    <div className="row">
                                        <div className="col-auto d-flex align-items-center flex-wrap">
                                            <h3 className="fw-bold fs-4 me-2">Должность:</h3>
                                            <h3 className="fs-4 w-100">{(resume?.name_post && resume?.name_post !== "") ? resume?.name_post : "Должность не выбрана"}</h3>
                                        </div>
                                    </div>
                                    <h3 className="fw-bold mt-2 fs-4">Навыки</h3>
                                    <div className="row row-cols-auto gap-3 ms-0">
                                        {resume?.skills?.length !== 0 && resume?.skills?.map((skill, index) => (
                                            <Tag tag={skill} styleClass={"bg-gray"} key={index} />
                                        ))}
                                        {resume?.skills?.length === 0 && <h3 className="fs-4 w-100 p-0">Навыки не выбраны</h3> }
                                    </div>
                                    <h3 className="fw-bold mt-3 fs-4">Желаемый формат работы</h3>
                                    <div className="d-flex row row-cols-auto gap-3 ms-0">
                                        {
                                            (resume?.work_format?.name !== null && resume?.work_format?.name !== undefined)  ? 
                                            <Tag tag={resume?.work_format?.name} />
                                            : <h3 className="fs-4 w-100 p-0">Формат работы не выбран</h3> 
                                        }
                                    </div>
                                    <h3 className="fw-bold mt-3 fs-4">Языки</h3>
                                    <div>
                                        {resume?.languages?.length !== 0 && resume?.languages?.map((language, index) => (
                                            <div className="mt-1" key={index}>
                                                <span className="fs-6">{language.name + ":"}</span>
                                                <span className="text-black text-decoration-underline  ms-2 fs-6">{language.level}</span>
                                            </div>
                                        ))} 
                                        {resume?.languages?.length === 0 && <h3 className="fs-4 w-100 p-0">Языки не выбраны</h3> }
                                    </div>  
                                </BoxMini>

                                <BoxMini styleClass="bg-white position-relative">
                                { !pdfHideComponents &&  parseInt(profileId) === store?.userBriefInformation?.user_id && <>
                                <div className="d-flex justify-content-end d-sm-none">
                                        <Link className="text-decoration-none fs-7" to="/editWorkExperiences" style={{ color: "blue" }}>
                                            <div className="align-items-center">
                                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                                <span className="fs-7" style={{ color: "blue" }}>Редактровать</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="d-none d-sm-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-4 ">
                                        <Link className="text-decoration-none fs-7" to="/editWorkExperiences" style={{ color: "blue" }}>
                                            <div className="align-items-center">
                                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                                <span className="fs-7" style={{ color: "blue" }}>Редактровать</span>
                                            </div>
                                        </Link>
                                    </div>
                                    </>}
                                    <div className="row">
                                        <h3 className="fw-bold mt-2 fs-4">Опыт работы</h3>
                                    </div>
                                    {resume?.experiences && resume?.experiences.map((experience) => (
                                        <BoxMap styleClass="" key={experience.id}>
                                            <div className="d-flex justify-content-start">
                                                <h5 className="fw-bold fs-5">{experience.title_position}</h5>
                                            </div>
                                            <h5 className="fw-bold mb-2" style={{ color: "blue" }}>{experience.company_name}</h5>
                                            <span className="mb-2 d-block" style={{ color: "gray" }}>{experience.getting_started} - {experience.end_work}</span>
                                            <span>{experience.description}</span>
                                            {resume && resume.experiences &&    
                                                experience.id !== resume?.experiences[resume?.experiences?.length - 1].id && (
                                                <hr className="mb-2" />
                                            )}
                                        </BoxMap>
                                    ))}
                                </BoxMini>

                                <BoxMini styleClass="bg-white mb-4 position-relative">
                                {!pdfHideComponents && parseInt(profileId) === store?.userBriefInformation?.user_id && <>
                                    <div className="d-flex justify-content-end d-sm-none">
                                        <Link className="text-decoration-none fs-7" to="/editEducations" style={{ color: "blue" }}>
                                            <div className="align-items-center">
                                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                                <span className="fs-7" style={{ color: "blue" }}>Редактровать</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="d-none d-sm-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-3 ">
                                        <Link className="text-decoration-none fs-7" to="/editEducations" style={{ color: "blue" }}>
                                            <div className="align-items-center">
                                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                                <span className="fs-7" style={{ color: "blue" }}>Редактровать</span>
                                            </div>
                                        </Link>
                                    </div>
                                    </>}
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="fw-bold mt-2 fs-4">Образование</h3>
                                        </div>
                                    </div>
                                    {educations && educations?.map((education) => (
                                        <BoxMap key={education.id} styleClass="mb-0">
                                            <div className="d-flex justify-content-start">
                                                <h5 className="fw-bold fs-5">{education.university?.name}</h5>
                                            </div>
                                            <h5 className="fw-bold mb-2" style={{ color: "blue" }}>{education.level_of_education}</h5>
                                            <span className="mb-2 d-block" style={{ color: "gray" }}>{education.year_of_admission + " - " + education.year_of_graduation}</span>
                                            <span>{education.specialty}</span>
                                            {education &&
                                                education.id !== educations[educations.length - 1].id && (
                                                    <hr className="mb-2" />
                                                )}
                                        </BoxMap>
                                    ))}
                                </BoxMini>
                            </div>
                        </div>}
                    </div>
                }
                {
                    !user && 
                    <Box styleClass="bg-white">
                        <div className="d-flex justify-content-center align-items-center"> 
                            <h3>Пользователь не найден</h3>
                        </div>
                    </Box>
                }

            </>

        );
    }
