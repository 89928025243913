import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import CodeInput from "../../components/CodeInput";
import { Context } from "../../App";
import AurhService from "../../api/services/AuthService"
import { useNavigate, useParams } from "react-router-dom";
import NotificationModal from "../../components/NotificationModal";

export function CompanyVerification(){
    useEffect(() => {
        document.title = "Поддверждение регистрации компании";
    }, []);

    const { id } = useParams<{ id: string }>();
    const typeOfCompany = id ?? "0"; // или любое другое значение по умолчанию
    const navigate = useNavigate();
    const store = useContext(Context);
    const [enterCode, setEnterCode] = useState<boolean>(false);
    const [password, setPassword] = useState<number>();
    const [inn, setInn] = useState<string>();
    const [companyName, setCompanyName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [errorNetwork, setErrorNetwork] = useState<string>('');

    const handleCodeChange = (newPassword: string) => {
        setPassword(parseInt(newPassword));
    };

    const handleCompanyRegistration = async() => {
        if((inn === ''|| inn === undefined) || (companyName === '' || companyName === undefined) || (phone === '' || phone === undefined) || (email === '' || email === undefined)){
            setError("Пожалуйста, заполните поле.")
            return 
        }

        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setEmailError("Некорректный email");
            return;
        }
        else{
            setEmailError('');
        }

        if(!store?.isAuth && (store?.userBriefInformation?.company_id === null || store?.userBriefInformation?.company_id === undefined)) {
            try{
                await AurhService.registration(email);
                setEnterCode(true);
            }
            catch (err: any) {
                if (err.response && err.response.status === 400) {
                    const errorMessage = err.response.data.detail?.email; // Извлечение сообщения об ошибке
                    setEnterCode(true);
                    if (errorMessage) {
                        setErrorNetwork(errorMessage);
                    } else {
                        setErrorNetwork("Неизвестная ошибка"); // Обработка случая, если сообщение отсутствует
                    }
                    setEnterCode(true);
                }
            }
        }
        else{
            if(inn !== undefined && companyName !== undefined && phone !== undefined && email !== undefined && (store?.userBriefInformation?.company_id === null || store?.userBriefInformation?.company_id === undefined)) {
                try{
                    store?.postCreateCompany(inn, companyName, phone, email, parseInt(typeOfCompany)); 
                }
                catch(err: any){
                    setErrorNetwork(err.detail);
                }
            }
            else{
                setErrorNetwork("У вас уже создана компания!");
            }
        }
    }

    useEffect(() => {
        if (errorNetwork) {
            const timer = setTimeout(() => {
                setErrorNetwork('');
            }, 10000); // 10 секунд

            return () => clearTimeout(timer); // Очистка таймера при размонтировании
        }
    }, [errorNetwork]);

    const handleCreateCompany = async() => {
        if (password !== undefined) {
            if(!store?.isAuth){
                await store?.login(email, password);
            }
            if(inn !== undefined && companyName !== undefined && phone !== undefined && email !== undefined && (store?.userBriefInformation?.company_id === undefined || store?.userBriefInformation?.company_id === null || store?.userBriefInformation?.company_id === 0)) {
                try{
                    await store?.postCreateCompany(inn, companyName, phone, email, parseInt(typeOfCompany));
                    navigate("/vacancy");
                }
                catch (err: any) {
                    if (err.response && err.response.status === 400) {
                        // console.log(err.response.data.detail);
                        const errorMessage = err.response.data.detail; // Извлечение сообщения об ошибке
                        if (errorMessage) {
                            setErrorNetwork(errorMessage + "Через 10 секунд произойдет автоматический переход на страницу вакансий.");
                        } else {
                            setErrorNetwork("У пользователя уже есть компания." + "Через 10 секунд произойдет автоматический переход на страницу вакансий."); // Обработка случая, если сообщение отсутствует
                        }
                    }
                }
                finally{
                    // console.log(errorNetwork)
                    if(errorNetwork !== '' || errorNetwork !== undefined || errorNetwork !== null){
                        navigate("/vacancy");
                    }
                }
            }
        }
    }

    return(
        <>
        {errorNetwork && (
                <NotificationModal 
                    message={errorNetwork} 
                    onClose={() => setErrorNetwork('')}
                    type="error"
                />
            )}
        <div className="container mt-5">
            <div className="text-left mb-4">
                    <h3 className="fw-bold">Шаг 2 из 3</h3>
                    <p>Вы регистрируетесь как:</p>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-3 me-5 h-100">
                        <div className="card text-center h-100 shadow-sm">
                            <div className="card-body">
                                {typeOfCompany === "1" && 
                                <div>
                                    <img src="/images/icons/legal-entity.png" className="card-img-top mb-3" alt="Юридическое лицо" />
                                    <h4 className="card-title fw-bold">Юридическое лицо</h4>
                                    <p style={{fontSize:"15px"}} className="card-text">Оформленное юр. лицо или ИП</p>
                                </div>}
                                { typeOfCompany === "2" && <div>
                                    <img src="/images/icons/individual-entrepreneur.png" className="card-img-top mb-3" alt="Физическое лицо" />
                                    <h4 className="card-title fw-bold">Физическое лицо</h4>
                                    <p style={{fontSize:"15px"}} className="card-text">Или оформили самозанятость</p>
                                </div>}
                                {/* { typeOfCompany === "3" && <div>
                                    <img src="/images/icons/university-representative.png" className="card-img-top mb-3" alt="Университет" />
                                    <h4 className="card-title fw-bold">Университет</h4>
                                    <p style={{fontSize:"15px"}} className="card-text">Вы являетесь представителем ВУЗа</p>
                                </div>} */}
                            </div>
                        </div>
                    </div>
                    {!enterCode ? <div className="col-md-7 mb-3">
                        <div className="card h-auto shadow-sm p-3">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="INN" className="form-label fw-bold">ИНН организации</label>
                                        <input type="text" maxLength={12} minLength={10} onChange={(e) => setInn(e.target.value)} className="form-control" id="INN" placeholder="Введите серию и номер" />
                                        {!inn && <span className="text-danger">{error}</span>}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="organizationName" className="form-label fw-bold">Название организации</label>
                                        <input type="text" className="form-control" onChange={(e) => setCompanyName(e.target.value)} id="organizationName" placeholder="Введите название" />
                                        {!companyName && <span className="text-danger">{error}</span>}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label fw-bold">Телефон для связи</label>
                                    <input type="text" maxLength={16} minLength={10} className="form-control" onChange={(e) => setPhone(e.target.value)} id="phone" placeholder="Введите номер телефона" />
                                    {!phone && <span className="text-danger">{error}</span>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label fw-bold">Email</label>
                                    <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} id="email" placeholder="Введите email" />
                                    {!email && <span className="text-danger">{error}</span>}
                                    {emailError !== '' && <span className="text-danger">{emailError}</span>}
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button className="btn-violet large px-5" onClick={() => handleCompanyRegistration()}>Продолжить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-md-7 mb-3">
                        <div className="card h-auto shadow-sm p-4 justify-content-center align-items-center ">
                            <CodeInput 
                            onCodeChange={handleCodeChange} 
                            email={email}
                            onClick={() => handleCreateCompany()}
                            companyRegistration={true}/>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}
